import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { EXPERIMENTS } from '@wix/communities-blog-client-common';
import EmptyStates from '../../../common/components/empty-states';
import { connect } from '../../../common/components/runtime-context';
import withExperiment from '../../../common/hoc/with-experiment';
import withTranslate from '../../../common/hoc/with-translate';
import CreatePostButton from '../create-post-button';

const NoPostsWriter = ({ category, t, isLiveSiteEditorEnabled, ...props }) => (
  <EmptyStates
    title={t('no-posts-writer.create-posts')}
    content={t('no-posts-writer.readers-would-love')}
    {...props}
  >
    {isLiveSiteEditorEnabled && (
      <CreatePostButton categorySlug={category && category.slug} />
    )}
  </EmptyStates>
);

NoPostsWriter.propTypes = {
  category: PropTypes.object,
  t: PropTypes.func,
  isLiveSiteEditorEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (_state, ownProps) => ({
  isLiveSiteEditingEnabled: !ownProps.isLiveSiteEditorDisabled,
});

export default flowRight(
  withTranslate,
  withExperiment({
    isLiveSiteEditorDisabled: EXPERIMENTS.LIVE_SITE_EDITOR_DEPRECATION,
  }),
  connect(mapRuntimeToProps),
)(NoPostsWriter);
