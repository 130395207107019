import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { EXPERIMENTS, resolveId } from '@wix/communities-blog-client-common';

import { connect } from '../../../common/components/runtime-context';
import withExperiment from '../../../common/hoc/with-experiment';
import { getIsRTL } from '../../../common/store/basic-params/basic-params-selectors';
import CreatePostButton from '../create-post-button';
import Masonry from './masonry-component';
import styles from './post-list.scss';

const MASONRY_OPTIONS = {
  transitionDuration: 0,
  fitWidth: false,
  gutter: 32,
};

const buildMasonryOptions = ({ isRTL }) => ({
  ...MASONRY_OPTIONS,
  originLeft: !isRTL,
});

const PostListMasonry = ({
  isRTL,
  category,
  posts,
  type,
  onLikeClick,
  showCreatePostAction,
  ItemComponent,
  itemConfig,
  isLiveSiteEditorEnabled,
}) => (
  <Masonry
    className={classNames(styles.masonry, styles[type])}
    options={buildMasonryOptions({ isRTL })}
    data-hook="post-list"
  >
    {showCreatePostAction && isLiveSiteEditorEnabled && (
      <div className={classNames(styles.listItem)} key="create-post">
        <CreatePostButton categorySlug={category && category.slug} />
      </div>
    )}
    {posts.map((post) => (
      <div
        className={classNames(styles.listItem, 'post-list__post-list-item')}
        key={resolveId(post)}
        id={resolveId(post)}
      >
        <ItemComponent
          type={type}
          post={post}
          onLikeClick={onLikeClick}
          itemConfig={itemConfig}
        />
      </div>
    ))}
  </Masonry>
);

PostListMasonry.propTypes = {
  isRTL: PropTypes.bool.isRequired,
  onLikeClick: PropTypes.func.isRequired,
  category: PropTypes.object,
  posts: PropTypes.array,
  type: PropTypes.string.isRequired,
  showCreatePostAction: PropTypes.bool,
  ItemComponent: PropTypes.func.isRequired,
  itemConfig: PropTypes.object,
  isLiveSiteEditorEnabled: PropTypes.bool,
};

const mapRuntimeToProps = (state, ownProps) => ({
  isRTL: getIsRTL(state),
  isLiveSiteEditingEnabled: !ownProps.isLiveSiteEditorDisabled,
});

export default flowRight(
  withExperiment({
    isLiveSiteEditorDisabled: EXPERIMENTS.LIVE_SITE_EDITOR_DEPRECATION,
  }),
  connect(mapRuntimeToProps),
)(PostListMasonry);
